exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2LUOHCoDD6LrlD5Q2qpYeA{position:absolute;top:0;left:0;right:0;height:2rem;display:flex;flex-direction:row;align-items:center;justify-content:space-between;z-index:1000}@media(min-width: 896px){._2LUOHCoDD6LrlD5Q2qpYeA{left:2rem;right:2rem}}._2xaiQoa1Is2iFKvfe1l6WS{color:#fff;font-family:\"rbw-mono\";display:inline;background:#0000a9;letter-spacing:.01rem;padding:.1rem .4rem .1rem;text-transform:uppercase;margin-right:1rem;height:2rem}._37XZ3zLhFjA0wVIAI5NdjT{display:flex;flex-direction:row;line-height:2rem;padding:0 .5rem}._37XZ3zLhFjA0wVIAI5NdjT button{display:inline-block;height:2rem}.ZcVmD2l3Znz1aOWq87Da7{color:#fff;padding-right:3px}", ""]);

// exports
exports.locals = {
	"wrap": "_2LUOHCoDD6LrlD5Q2qpYeA",
	"room-name": "_2xaiQoa1Is2iFKvfe1l6WS",
	"buttons": "_37XZ3zLhFjA0wVIAI5NdjT",
	"player-count": "ZcVmD2l3Znz1aOWq87Da7"
};