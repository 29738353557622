exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Uo9XUJ-QUo4QBYO-FrE_d{font-family:\"rbw-super-mono\";color:#f3f3f3;font-size:2rem;position:absolute;z-index:20;top:0;right:2rem;height:8rem;display:flex;align-items:center;justify-content:center;border-bottom:2px transparent}._2Uo9XUJ-QUo4QBYO-FrE_d ._1HfniXOx22BMb6X6dxYVf4{height:1.5rem}._2Uo9XUJ-QUo4QBYO-FrE_d svg{height:1.5rem;width:1.5rem;fill:#f3f3f3;margin-right:1rem;opacity:.4;vertical-align:top;line-height:0}._2Uo9XUJ-QUo4QBYO-FrE_d span{opacity:.4;transform:translate(0px, -2px)}._2Uo9XUJ-QUo4QBYO-FrE_d ._3mGFDntXKWI4mb7wa-0AH9,._2Uo9XUJ-QUo4QBYO-FrE_d ._2yUQ1oUY6W1BIP9Ys24jrp{display:inline-block}._2Uo9XUJ-QUo4QBYO-FrE_d ._3mGFDntXKWI4mb7wa-0AH9{text-align:right}", ""]);

// exports
exports.locals = {
	"timer-wrap": "_2Uo9XUJ-QUo4QBYO-FrE_d",
	"icon-wrap": "_1HfniXOx22BMb6X6dxYVf4",
	"minutes": "_3mGFDntXKWI4mb7wa-0AH9",
	"seconds": "_2yUQ1oUY6W1BIP9Ys24jrp"
};