exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4Fiwo6WipLGG7h-wCR-4S{position:absolute;top:0;right:0;left:0;height:8rem;border-bottom:2px solid #f3f3f3;z-index:11;text-align:center;display:flex;align-items:center;justify-content:flex-start;box-sizing:border-box;background:#202020}._2tb9EIVInXTqOYzgpGNBIN{position:absolute;top:8rem;right:0;left:0;padding-bottom:8rem;overflow:visible}@media(min-width: 896px){._2tb9EIVInXTqOYzgpGNBIN{bottom:0;padding-bottom:0;position:absolute;overflow-y:scroll}._2tb9EIVInXTqOYzgpGNBIN::-webkit-scrollbar{display:none}}._1b3RXNt9FdAANLeEyvGkoW{position:relative;width:100%;height:100%;border:2px solid;margin:0;border-bottom:none}@media(min-width: 896px){._1b3RXNt9FdAANLeEyvGkoW{margin:0 2rem;margin-right:0;border-right:none;border-bottom:2px solid}}._3Ql8j3yClCBDRVMubNzo5T{color:#fff;padding:1rem 2rem;font-size:1.2rem;flex:1 1 auto;border-bottom:2px solid #e2e2e2}._3Ql8j3yClCBDRVMubNzo5T span{opacity:.5}.B88wbzQPBk8y8XUyHWY_z{color:#fff;padding:1rem;background:rgba(234,125,42,.5);border-bottom:2px solid #ea7d2a}", ""]);

// exports
exports.locals = {
	"top-section": "_4Fiwo6WipLGG7h-wCR-4S",
	"bottom-section": "_2tb9EIVInXTqOYzgpGNBIN",
	"game-wrap": "_1b3RXNt9FdAANLeEyvGkoW",
	"playing-info": "_3Ql8j3yClCBDRVMubNzo5T",
	"voting-alert-not-playing": "B88wbzQPBk8y8XUyHWY_z"
};