exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ooXn7AkBlufeQ8WNeRVdV{overflow:hidden;position:fixed;top:0;right:0;left:0;bottom:0;background:#202020;color:#f3f3f3}._8SzfRtzgOTGwHD8IjXQyU{margin:1rem;height:calc(100vh - (2 * 1rem));border-bottom:none;border-top:none}@media(min-width: 896px){._8SzfRtzgOTGwHD8IjXQyU{margin:2rem;height:calc(100vh - (2 * 2rem))}}._2cGAMJl0HL_1Lv2U1FDb0r{height:100%;position:relative;display:flex;align-items:center;justify-content:center}._1VSPYsQzNX4wXN5ux0nkfp{position:absolute;top:0;left:0;z-index:9999}._3D6o4ofAMXnDJbPOpNVoDQ{border:2px solid #f3f3f3}.W9T1xxQT-FwqJHE9Mu3Cm{position:relative}@media(min-width: 896px){.W9T1xxQT-FwqJHE9Mu3Cm{position:absolute;left:calc(50vw - 1rem);top:calc(50vh - 4rem)}}._9skquWrKMf-opWnP-q8op{position:relative}@media(min-width: 896px){._9skquWrKMf-opWnP-q8op{position:fixed;left:2rem;bottom:2rem}}", ""]);

// exports
exports.locals = {
	"landing": "ooXn7AkBlufeQ8WNeRVdV",
	"main-outer": "_8SzfRtzgOTGwHD8IjXQyU",
	"main-full": "_2cGAMJl0HL_1Lv2U1FDb0r",
	"logo": "_1VSPYsQzNX4wXN5ux0nkfp",
	"button-wrap": "_3D6o4ofAMXnDJbPOpNVoDQ",
	"boring": "W9T1xxQT-FwqJHE9Mu3Cm",
	"website": "_9skquWrKMf-opWnP-q8op"
};