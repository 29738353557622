exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Ws8QkYCtZDk8BIqKYvqJ{border:2px solid #f3f3f3;position:absolute;right:0;bottom:0;left:0;top:0;background:#202020;padding:1rem;overflow:scroll;padding-bottom:4rem}._2Ws8QkYCtZDk8BIqKYvqJ::-webkit-scrollbar{display:none}._2Ws8QkYCtZDk8BIqKYvqJ h1{font-size:4rem;margin:0;margin-bottom:1rem}._2Ws8QkYCtZDk8BIqKYvqJ ._2cm2ETF9zW-qGFJLWig-NS{margin-left:-1rem}._2Ws8QkYCtZDk8BIqKYvqJ ._17_nPM-6V7eHswedbGnuPy{background:rgba(243,243,243,.9);color:#202020;padding:1rem}._2Ws8QkYCtZDk8BIqKYvqJ a{color:#f3f3f3}._2Ws8QkYCtZDk8BIqKYvqJ .n9Hzu3GRv9b2zNO1wwLPu{margin:1em 0}", ""]);

// exports
exports.locals = {
	"rulebook": "_2Ws8QkYCtZDk8BIqKYvqJ",
	"bump-left": "_2cm2ETF9zW-qGFJLWig-NS",
	"important": "_17_nPM-6V7eHswedbGnuPy",
	"p": "n9Hzu3GRv9b2zNO1wwLPu"
};