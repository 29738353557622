exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".D0DhezCXuj8mXxvRdGdfJ{position:relative;padding:4rem;padding-top:5rem;overflow:scroll;height:100vh;width:100%;position:absolute;top:0;padding-bottom:34rem}@media(min-width: 896px){.D0DhezCXuj8mXxvRdGdfJ{padding-bottom:4rem}}.f55-YJ_-2SinZhPyFQuEA{opacity:1;max-width:620px;margin:0 auto;text-align:center}.f55-YJ_-2SinZhPyFQuEA ._1R65rj5xdP1Pgo9jJm9gdl{border:2px solid #e2e2e2;border-radius:5px;background:#fff}.f55-YJ_-2SinZhPyFQuEA ._9vMr-g5J9iyt896i4mMF1{margin-bottom:1rem;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.f55-YJ_-2SinZhPyFQuEA ._9vMr-g5J9iyt896i4mMF1 ._3p9NQVhELe3S0dRf-CrP-Y{display:flex;flex-direction:row;align-items:center;justify-content:space-between}.f55-YJ_-2SinZhPyFQuEA ._9vMr-g5J9iyt896i4mMF1 ._3p9NQVhELe3S0dRf-CrP-Y button{margin-left:.5rem}.f55-YJ_-2SinZhPyFQuEA ._1NNDBZX_bP4l25NWAZM7RA{margin-top:1rem;display:grid;grid-template-columns:1fr 1fr}.f55-YJ_-2SinZhPyFQuEA .react-colorful{height:100px}.f55-YJ_-2SinZhPyFQuEA .rc-slider{padding:2rem 0;height:auto}.f55-YJ_-2SinZhPyFQuEA .rc-slider .rc-slider-rail{background-color:#393939}.f55-YJ_-2SinZhPyFQuEA .rc-slider .rc-slider-track{background-color:#e2e2e2}.f55-YJ_-2SinZhPyFQuEA .rc-slider .rc-slider-handle{border:none;height:2rem;width:2rem;margin-top:-1rem}", ""]);

// exports
exports.locals = {
	"game-wrap-scrollable": "D0DhezCXuj8mXxvRdGdfJ",
	"canvas-wrap": "f55-YJ_-2SinZhPyFQuEA",
	"canvas": "_1R65rj5xdP1Pgo9jJm9gdl",
	"controls-above": "_9vMr-g5J9iyt896i4mMF1",
	"button-group": "_3p9NQVhELe3S0dRf-CrP-Y",
	"controls-under": "_1NNDBZX_bP4l25NWAZM7RA"
};