exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1t5ASVa2AblfCkADK-1Zio._3Js0EbJLRnmXztDeBnAx7s button{background:#f93a26;color:#f3f3f3}._1t5ASVa2AblfCkADK-1Zio._3Js0EbJLRnmXztDeBnAx7s button:hover{background:#ce0f0f;color:#f3f3f3}._1t5ASVa2AblfCkADK-1Zio button{padding:.5rem .8rem;border-radius:0px;transition:150ms ease all;border:2px solid #f3f3f3;border-radius:4px}._1t5ASVa2AblfCkADK-1Zio button:hover{background:#202020;color:#f3f3f3}._1t5ASVa2AblfCkADK-1Zio button:active{transform:scale(0.98)}._1t5ASVa2AblfCkADK-1Zio .XiP8K-Jp_HdZOzAvoEDhr{font-family:\"rbw-sans\";font-size:1rem;font-weight:bold}", ""]);

// exports
exports.locals = {
	"system-button": "_1t5ASVa2AblfCkADK-1Zio",
	"dangerous": "_3Js0EbJLRnmXztDeBnAx7s",
	"text": "XiP8K-Jp_HdZOzAvoEDhr"
};