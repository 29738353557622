import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "paper";
import cssModules from "react-css-modules";
import styles from "Styles/pictionary/pictionary.scss";
import api from "Actions/api";
import SystemButton from "Components/ui/SystemButton";
import ScoreBoard from "Components/game/ScoreBoard";
import SvgIcon from "Components/utility/SvgIcon";

import ConfirmButton from "Components/ui/ConfirmButton";
import { HexColorPicker } from "react-colorful";
import Slider from "rc-slider";

const strokeWeights = [1, 3, 7, 10, 20, 40, 100];

const CANVAS_SIZE = 465;
const CANVAS_SIZES = {
  SMALL: 300,
  // MEDIUM: 465,
  LARGE: 600,
};
class Pictionary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myTurn: false,
      strokeWidth: strokeWeights[2],
      strokeColor: "#000000",
      opacity: 1,
      word: "",
      canvasSize: 300,
    };
    this.canvasSize = CANVAS_SIZES.LARGE;
    this.cleanUpFns = [];
  }
  componentDidMount() {
    if (
      this.props.playersTurn &&
      this.props.uid &&
      this.props.playersTurn === this.props.uid
    ) {
      this.handleMyTurn();
    }

    window.addEventListener("resize", this.onResize);
    this.cleanUpFns.push(() => {
      window.removeEventListener("resize", this.onResize);
    });

    Paper.setup("pictionary-canvas");
    Paper.activate();
    Paper.project.addLayer([]);
    this.Paper = Paper;
    Paper.view.onMouseDown = (event) => {
      if (this.state.myTurn) {
        this.drawStart = new Date().getTime();
        this.path = new Paper.Path({
          segments: [event.point],
          strokeColor: this.state.strokeColor,
          strokeWidth: this.state.strokeWidth,
          strokeCap: "round",
          strokeJoin: "round",
          fullySelected: false,
        });
      }
    };
    Paper.view.onMouseDrag = (event) => {
      if (this.state.myTurn) {
        this.path.add(event.point);
      }
    };
    Paper.view.onMouseUp = (event) => {
      if (this.state.myTurn) {
        const delta = Math.min(
          4000,
          Math.max(10, Math.trunc((new Date().getTime() - this.drawStart) / 2)),
        );
        // When the mouse is released, simplify it:
        this.path.simplify(10);

        api.sendLine({
          d: delta,
          l: this.path.getPathData(this.path.matrix, 3),
          w: this.state.strokeWidth,
          c: this.state.strokeColor,
          // o: this.state.opacity,
        });
        Paper.project.activeLayer.addChild(this.path);
      }
    };
    this.onResize();
    const handleAllLines = (lines) => {
      lines.forEach((newLine) => {
        const drawing = new Paper.Path(newLine.l);
        drawing.strokeColor = newLine.c;
        drawing.strokeWidth = newLine.w;
        drawing.strokeCap = "round";
        drawing.strokeJoin = "round";
        Paper.project.activeLayer.addChild(drawing);
      });
      Paper.view.update();
    };
    const handleNewLine = (newLine) => {
      if (this.state.myTurn) {
        return;
      }
      const drawing = new Paper.Path(newLine.l);
      drawing.strokeColor = newLine.c;
      drawing.strokeWidth = newLine.w;
      drawing.strokeCap = "round";
      drawing.strokeJoin = "round";
      const duration = newLine.d;

      drawing.dashArray = [drawing.length, drawing.length];
      drawing.tween({
        duration,
      }).onUpdate = function (event) {
        drawing.dashOffset = drawing.length * (1 - event.factor);
      };
      Paper.project.activeLayer.addChild(drawing);

      Paper.view.update();
    };
    const handleUndo = () => {
      const drawnPaths = Paper.project.activeLayer.children.length;
      if (drawnPaths > 0) {
        Paper.project.activeLayer.removeChildren(drawnPaths - 1);
      }
    };
    const handleReset = () => {
      if (Paper.project.layers.length > 0) {
        Paper.project.activeLayer.clear();
        Paper.view.draw();
      }
    };

    const unsub = api.subscribeToBoardActions(
      this.props.roomName,
      handleNewLine,
      handleUndo,
      handleReset,
      handleAllLines,
    );
    this.cleanUpFns.push(unsub);
  }
  onResize = () => {
    // console.log(this.Paper.view.scaling);
    if (window.innerWidth > 1000) {
      this.setState({
        canvasSize: 600,
      }, () => {
        this.Paper.view.viewSize.set(this.state.canvasSize, this.state.canvasSize);
      });
      if (this.canvasSize === CANVAS_SIZES.SMALL) {
        this.Paper.view.scale(2, [0, 0]);
        this.canvasSize = CANVAS_SIZES.LARGE;
      }
    } else {
      this.setState({
        canvasSize: 300,
      }, () => {
        this.Paper.view.viewSize.set(this.state.canvasSize, this.state.canvasSize);
      });
      if (this.canvasSize === CANVAS_SIZES.LARGE) {
        this.Paper.view.scale(1 / 2, [0, 0]);
        this.canvasSize = CANVAS_SIZES.SMALL;
      }
    }
    // if (this.canvasSize !== CANVAS_SIZES.LARGE) {
    // 
    // this.Paper.view.viewSize.center = new Paper.Point(this.state.canvasSize / 2, this.state.canvasSize / 2);

    // this.Paper.view.scale(2, [0, 0]);
    // this.canvasSize = CANVAS_SIZES.LARGE;
    // console.log(this.Paper.view.scaling, this.Paper.view.center);
    // }
    // } else if (window.innerWidth > 500) {
    //   if (this.canvasSize !== CANVAS_SIZES.MEDIUM) {
    //     this.Paper.view.viewSize.set(CANVAS_SIZE, CANVAS_SIZE);
    //     this.Paper.view.viewSize.center = new Paper.Point(200, 200);
    //     if (this.canvasSize) {
    //       this.Paper.view.scale(CANVAS_SIZES.MEDIUM / this.canvasSize, [0, 0]);
    //     }

    //     this.canvasSize = CANVAS_SIZES.MEDIUM;
    //     // console.log(this.Paper.view.scaling, this.Paper.view.center);
    //   }
    // } else {
    //   if (this.canvasSize !== CANVAS_SIZES.SMALL) {
    //     this.Paper.view.viewSize.set(310, 310);
    //     this.Paper.view.scale(2 / 3, [0, 0]);
    //     this.Paper.view.viewSize.center = new Paper.Point(155, 155);

    //     this.canvasSize = CANVAS_SIZES.SMALL;
    //     // console.log(this.Paper.view.scaling, this.Paper.view.center);
    //   }
    // }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.playersTurn !== this.props.playersTurn ||
      prevProps.roundNonce !== this.props.roundNonce
    ) {
      if (
        this.props.playersTurn &&
        this.props.uid &&
        this.props.playersTurn === this.props.uid
      ) {
        this.handleMyTurn();
      } else {
        this.setState({
          myTurn: false,
        });
      }
    }
  }
  componentWillUnmount() {
    this.cleanUpFns.map((fn) => fn());
  }
  sendUndo = () => {
    if (this.state.myTurn) {
      api.sendUndo(this.props.roomName);
    }
  };
  kickUser = (userId) => {
    api.sendKickUser(this.props.roomName, userId);
  };
  sendReset = () => {
    if (this.state.myTurn) {
      api.sendReset(this.props.roomName);
    }
  };
  setColor = (c) => {
    this.setState({
      strokeColor: c,
    });
  };
  setWeight = (num) => {
    // console.log(num, strokeWeights[num]);
    this.setState({
      strokeWidth: strokeWeights[num],
    });
  };
  handleMyTurn = () => {
    this.setState({
      myTurn: true,
    });
    // console.log("get word");
    api.getWord(this.props.roomName, (word) => {
      this.setState({
        word,
      });
    });
  };

  render() {
    const { myTurn, word } = this.state;
    const { playersTurn, members, host, uid } = this.props;
    // const color = toColor('hex', this.state.strokeColor);
    let drawingName = "";

    if (playersTurn in members) {
      drawingName = members[playersTurn].name;
    }
    const iAmHost = host === uid;

    return (
      <div styleName="game-wrap-scrollable">
        <div styleName="canvas-wrap">
          {myTurn ? (
            <div styleName="controls-above">
              <div>
                <div className="rbw-text">It's your turn! Draw:</div>
                <div className="rbw-text-l">{word}</div>
              </div>
              <div styleName="button-group">
                <SystemButton onClick={this.sendUndo}>
                  Undo Last Line
                </SystemButton>
                <SystemButton onClick={this.sendReset} dangerous>
                  Clear
                </SystemButton>
              </div>
            </div>
          ) : (
            <div styleName="controls-above">
              <div>
                <div className="rbw-text-l">{drawingName} is drawing </div>

                <div style={{ marginBottom: "0.5rem" }} />
                <div className="rbw-text">Guess in the chat!</div>
              </div>
              <div>
                {iAmHost && (
                  <ConfirmButton onClick={() => this.kickUser(playersTurn)}>
                    Kick
                  </ConfirmButton>
                )}
              </div>
            </div>
          )}
          <canvas
            styleName="canvas"
            id="pictionary-canvas"
            // keepalive={true}
            resize="true"
            style={{ height: this.state.canvasSize, width: this.state.canvasSize }}
          />
          {myTurn && (
            <div styleName="controls-under">
              <HexColorPicker
                color={this.state.strokeColor}
                onChange={this.setColor}
              />
              <div>
                <div className="rbw-text"> Stroke Weight</div>

                <Slider
                  min={0}
                  defaultValue={2}
                  max={strokeWeights.length - 1}
                  onChange={this.setWeight}
                />
              </div>
            </div>
          )}
        </div>
        <ScoreBoard
          members={members}
          scores={this.props.scores}
          // lastScores={this.props.lastScores}
          host={this.props.host}
        />
        {iAmHost && (
          <div>
            <SystemButton onClick={api.changeGameMode}>
              Switch game mode
            </SystemButton>
          </div>
        )}
      </div>
    );
  }
}

Pictionary.propTypes = {};

export default cssModules(Pictionary, styles, { allowMultiple: true });
