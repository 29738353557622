exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".YfjVoOqB1GQGCalajmm3N{font-size:1em;padding:0;display:flex;align-items:center;justify-content:center;height:100%;padding:0 2rem;transition:200ms linear background,100ms linear transform;font-family:\"rbw-text\";border:1px solid #909090}.YfjVoOqB1GQGCalajmm3N:hover{background:#f2f2f2}.YfjVoOqB1GQGCalajmm3N:active{transform:scale(0.98)}.YfjVoOqB1GQGCalajmm3N ._3UDgh6W9CrovzzZqpispX1{color:#e2e2e2;background:#fff}", ""]);

// exports
exports.locals = {
	"button": "YfjVoOqB1GQGCalajmm3N",
	"default": "_3UDgh6W9CrovzzZqpispX1"
};