exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1L9eCWW3qiNP0LmCKFxFjb{position:relative;display:flex;flex-direction:row}._1L9eCWW3qiNP0LmCKFxFjb ._13nJWfpH_bLhcO2hiyqi7k{flex:1 1 auto}._1L9eCWW3qiNP0LmCKFxFjb ._13nJWfpH_bLhcO2hiyqi7k input{color:#f3f3f3;font-size:1.3rem}@media(max-width: 895px){._1L9eCWW3qiNP0LmCKFxFjb ._13nJWfpH_bLhcO2hiyqi7k input{font-size:16px}}._1L9eCWW3qiNP0LmCKFxFjb ._13nJWfpH_bLhcO2hiyqi7k input::placeholder{color:#909090}._1L9eCWW3qiNP0LmCKFxFjb .tehhu53kBREeTrKrd3mAV{border-left:2px solid #f3f3f3;flex:0 0 auto;background:#202020;font-size:1.3rem}._1L9eCWW3qiNP0LmCKFxFjb .tehhu53kBREeTrKrd3mAV button{background:#202020;color:#f3f3f3}@media(max-width: 895px){._1L9eCWW3qiNP0LmCKFxFjb .tehhu53kBREeTrKrd3mAV button{font-size:16px}}", ""]);

// exports
exports.locals = {
	"input-button": "_1L9eCWW3qiNP0LmCKFxFjb",
	"input": "_13nJWfpH_bLhcO2hiyqi7k",
	"button": "tehhu53kBREeTrKrd3mAV"
};