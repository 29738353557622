import React from "react";

import History from "Util/History";
import {
  setUserData,
  setUserName,
  setUserRoom,
  setUserInitialized,
} from "Actions";
import localForage from "localforage";
import { createDevLog } from "Util/devlog";
let autoDisconnect = null;
import emitter from "Util/emitter";

const devlog = createDevLog("user");

const welcomeMessage = () => {
  setTimeout(() => {
    emitter.emit("alert", {
      message: (
        <div>
          👋&nbsp;&nbsp;&nbsp;Hello! Thanks for playing this game.
          {/* Some updates coming:
          <br />
          <li>Create public rooms that show up in the lobby.</li>
          <li>Host can change time amount.</li>
          <li>Create and save custom lists.</li> */}
        </div>
      ),
      type: "info",
      offsetTime: 0,
    });
    emitter.emit("alert", {
      message: (
        <div>
          💻&nbsp;&nbsp;&nbsp;I am actively developing improvements. Email me at{" "}
          <a
            className="donate-link-button rbw-text"
            href="mailto:wendygarfo@gmail.com"
          >
            wendygarfo@gmail.com
          </a>{" "}
          if you have feature requests/questions.
        </div>
      ),
      type: "info",
      offsetTime: 1,
    });

    emitter.emit("alert", {
      message: (
        <div>
          🎉&nbsp;&nbsp;&nbsp;Recently I added{" "}
          <span
            style={{
              fontWeight: "bold",
              backgroundImage:
                "linear-gradient(68deg,#b594ff, #49ffe1, #9eff49, #ffae49, #ff4a9c)",
              webkitBackgroundClip: "text",
              webkitTextFillColor: "transparent",
            }}
          >
            PICTIONARY
          </span>
          . Try it out!
        </div>
      ),
      type: "event",
      offsetTime: 2,
    });

    emitter.emit("alert", {
      message: (
        <div>
          <div>
            This game is free, but if you enjoy playing, please consider
            donating to help server costs.
            <div style={{ height: "5px" }}></div>
          </div>
          <a
            target="_blank"
            href="https://www.paypal.com/donate?business=KSBBHXLCF8JDL&item_name=Really+Boring+Website&currency_code=USD"
          >
            ☕️&nbsp;&nbsp;Donate
          </a>{" "}
          or{"  "}
          <a href="https://www.patreon.com/swellgarfo" target="_blank">
            🙏&nbsp;&nbsp;Subscribe on Patreon
          </a>
          <div style={{ height: "5px" }}></div>
        </div>
      ),
      type: "info",
      offsetTime: 3,
    });
  }, 20);
};

const disconnectUser = (dispatch, socket) => {
  dispatch(setUserName(""));
  dispatch(setUserRoom("general"));
  History.push("/");
  socket.disconnect();
  setTimeout(() => {
    localForage.clear();
    alert("You were disconnected.");
    document.location = "/";
  }, 2000);
};

export default (socket) => ({
  registerUser(dispatch, userObj, isInitialized) {
    console.log("registerUser", userObj, isInitialized);
    if (isInitialized) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      welcomeMessage();
      socket.once("existingUser", (user) => {
        devlog("User Restored", user);
        dispatch(setUserData(user));
        if (user.room && user.room !== "general") {
          History.push(`/room/${user.room}`);
        }
        dispatch(setUserInitialized());
        resolve();
        socket.on("kickPlayer", ({ id }) => {
          if (id === user.id) {
            disconnectUser(dispatch, socket);
          }
        });
      });
      socket.once("newUser", (user) => {
        devlog("New User Created", user);

        if (user.room) {
          History.push(`/room/${user.room}`);
        } else if (user.name) {
          History.push(`/lobby`);
        } else {
          History.push("/");
        }
        dispatch(setUserInitialized());
        resolve();
        socket.on("kickPlayer", (id) => {
          if (id === user.id) {
            disconnectUser(dispatch, socket);
          }
        });
      });

      devlog("Register User", userObj);
      socket.emit("registerUser", userObj);

      window.addEventListener("keyup", () => {
        clearTimeout(autoDisconnect);
        autoDisconnect = setTimeout(() => {
          disconnectUser(dispatch, socket);
        }, 120 * 60 * 1000);
      });
    });
  },
  nameUser(name, initialRoom) {
    devlog("nameUser(", name, initialRoom);
    if (name) {
      socket.emit("nameUser", name, initialRoom);
    }
  },

  setUserRoom(room) {
    devlog("setUserRoom(", room);
    if (room) {
      socket.emit("setUserRoom", room);
    }
  },
});
