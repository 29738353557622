var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "::-webkit-input-placeholder{color:#e2e2e2;font-weight:normal}::-moz-placeholder{color:#e2e2e2;font-weight:normal}:-ms-input-placeholder{color:#e2e2e2;font-weight:normal}:-moz-placeholder{color:#e2e2e2;font-weight:normal}::placeholder{color:#e2e2e2;font-weight:normal}._1o-oBuxOODDJ9itv24mU9L{position:relative;height:5rem;width:100%;background-image:url(" + escape(require("../../assets/img/pattern.png")) + ");background-size:60rem;background-position:center;background-repeat:repeat}@media(min-width: 896px){._1o-oBuxOODDJ9itv24mU9L{height:5rem}}._1o-oBuxOODDJ9itv24mU9L .mx0YLr3ivzFf8rjTY65qH{height:100%;width:100%;display:flex;align-items:center;justify-content:center}._1o-oBuxOODDJ9itv24mU9L input{border:none;outline:none;border-radius:0px;background:transparent;font-family:\"rbw-text\";font-size:1em;height:100%;color:#e2e2e2;padding-left:1rem}@media(max-width: 895px){._1o-oBuxOODDJ9itv24mU9L input{font-size:16px;padding:10px}}._1o-oBuxOODDJ9itv24mU9L ._2dhsm4u9T-b49lihy308wh{position:absolute;top:0;bottom:0;left:0;right:0;z-index:2}._1o-oBuxOODDJ9itv24mU9L ._1A-s1s4AVdn3h6tYbxXUfg{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1;overflow:hidden}._1o-oBuxOODDJ9itv24mU9L ._1A-s1s4AVdn3h6tYbxXUfg ._3IcdNgCNaG2t6uNMVP8IVT{background:#202020;font-family:\"rbw-text\";font-size:2em;margin:.3em .6em;margin-left:0;padding-left:.6em;display:inline-block;vertical-align:bottom;position:relative;padding-right:.2em;color:transparent;white-space:nowrap}._1o-oBuxOODDJ9itv24mU9L ._1A-s1s4AVdn3h6tYbxXUfg ._3IcdNgCNaG2t6uNMVP8IVT::before{content:\"|\";opacity:0}._1o-oBuxOODDJ9itv24mU9L ._1A-s1s4AVdn3h6tYbxXUfg ._3XaT_NLqSxOg6UScNvT4E4{height:3px;background:#0bd44a;position:absolute;top:50%;left:.6em;right:.6em;z-index:-1}", ""]);

// exports
exports.locals = {
	"input-wrap": "_1o-oBuxOODDJ9itv24mU9L",
	"input-inner": "mx0YLr3ivzFf8rjTY65qH",
	"foreground": "_2dhsm4u9T-b49lihy308wh",
	"background": "_1A-s1s4AVdn3h6tYbxXUfg",
	"background-mirror": "_3IcdNgCNaG2t6uNMVP8IVT",
	"background-line": "_3XaT_NLqSxOg6UScNvT4E4"
};