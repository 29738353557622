exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Tf1AByvo3YupNzZk-lL_e._3PyPqsBtaLuX75EfU2NX3I{background:transparent}._1Tf1AByvo3YupNzZk-lL_e._3PyPqsBtaLuX75EfU2NX3I .cVNPEqnX-amkUgdps2_G0{background:blue}._1Tf1AByvo3YupNzZk-lL_e._3PyPqsBtaLuX75EfU2NX3I ._22XzKl19q0fxkiahNXP6X>div:first-child{color:#a2a4a5}._1Tf1AByvo3YupNzZk-lL_e._3PyPqsBtaLuX75EfU2NX3I .h9-xwjSFaFQdS2qkv6Fsd{transform:translate(2rem, 0)}._1Tf1AByvo3YupNzZk-lL_e.r1WjokRuR6v6hGnxHfN7W{background:transparent}._1Tf1AByvo3YupNzZk-lL_e.r1WjokRuR6v6hGnxHfN7W .cVNPEqnX-amkUgdps2_G0{background:#202020}._1Tf1AByvo3YupNzZk-lL_e.r1WjokRuR6v6hGnxHfN7W ._22XzKl19q0fxkiahNXP6X>div:last-child{color:#a2a4a5}._1Tf1AByvo3YupNzZk-lL_e.r1WjokRuR6v6hGnxHfN7W .h9-xwjSFaFQdS2qkv6Fsd{transform:none}._1Tf1AByvo3YupNzZk-lL_e ._2W0obv57cffAqtXVA6UIHk{padding:.5rem .8rem;background:transparent;color:#fff;box-sizing:border-box;font-family:\"rbw-sans\";font-weight:bold}._1Tf1AByvo3YupNzZk-lL_e ._2W0obv57cffAqtXVA6UIHk ._22XzKl19q0fxkiahNXP6X{display:flex;flex-direction:row;justify-content:center;align-items:center;box-sizing:border-box;font-size:1.3rem}._1Tf1AByvo3YupNzZk-lL_e ._2W0obv57cffAqtXVA6UIHk .cVNPEqnX-amkUgdps2_G0{width:4rem;height:2rem;border-radius:1000px;border:2px solid #e2e2e2;position:relative;box-sizing:content-box;margin:0 1rem}._1Tf1AByvo3YupNzZk-lL_e ._2W0obv57cffAqtXVA6UIHk .cVNPEqnX-amkUgdps2_G0 .h9-xwjSFaFQdS2qkv6Fsd{height:2rem;width:2rem;border-radius:1000px;background:#fff;position:absolute;top:0;left:0;bottom:0;box-sizing:border-box;transition:200ms ease}", ""]);

// exports
exports.locals = {
	"toggle-wrap": "_1Tf1AByvo3YupNzZk-lL_e",
	"on": "_3PyPqsBtaLuX75EfU2NX3I",
	"toggle-track": "cVNPEqnX-amkUgdps2_G0",
	"toggle-inner": "_22XzKl19q0fxkiahNXP6X",
	"toggle-circle": "h9-xwjSFaFQdS2qkv6Fsd",
	"off": "r1WjokRuR6v6hGnxHfN7W",
	"toggle": "_2W0obv57cffAqtXVA6UIHk"
};