exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2anLhzl_j2BtSK1M6PBHpA{border:2px solid #f3f3f3;position:absolute;right:0;bottom:0;left:0;top:0;background:#202020;padding:1rem 0;overflow:scroll;padding-bottom:4rem}._2anLhzl_j2BtSK1M6PBHpA::-webkit-scrollbar{display:none}._2anLhzl_j2BtSK1M6PBHpA h1{font-size:4rem;margin:0;margin-bottom:1rem;padding:0 2rem}._2anLhzl_j2BtSK1M6PBHpA ._298YWYhQJNoYHPJsr9FV2x{margin-left:-1rem}._2anLhzl_j2BtSK1M6PBHpA ._3foOTRALPWDOsxTbYryTpw{background:rgba(243,243,243,.9);color:#202020;padding:1rem}._2anLhzl_j2BtSK1M6PBHpA a{color:#f3f3f3}._2anLhzl_j2BtSK1M6PBHpA ._2iX85qoOakrSYSuRp84e3o{margin:1em 0}", ""]);

// exports
exports.locals = {
	"rulebook": "_2anLhzl_j2BtSK1M6PBHpA",
	"bump-left": "_298YWYhQJNoYHPJsr9FV2x",
	"important": "_3foOTRALPWDOsxTbYryTpw",
	"p": "_2iX85qoOakrSYSuRp84e3o"
};